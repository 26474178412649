<script>
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import isEqual from 'lodash/isEqual';
import map from 'lodash/map';
import mapKeys from 'lodash/mapKeys';
import omit from 'lodash/omit';
import upperFirst from 'lodash/upperFirst';
import { mapActions, mapMutations, mapState } from 'vuex';
import {
  MuiAlgoliaSelect,
  MuiInputText,
  MuiSelect,
  MuiValidationWrapper,
  Validate,
} from '@emobg/motion-ui/v1';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import algoliaIndexes from '@/constants/algoliaIndexes';
import {
  CancelButton,
  ContentCellComponent,
  GenericModalComponent,
  StoreNotificationComponent,
  TranslatableFieldComponent,
} from '@/components';
import { SERVICE_GROUPS, SERVICE_UNITS } from '../../const/services.const';
import { scopes as SERVICES_SCOPES } from '../../store/ServicesModule';

export default {
  name: 'ServiceFormComponent',
  directives: {
    Validate,
  },
  components: {
    CancelButton,
    ContentCellComponent,
    GenericModalComponent,
    MuiAlgoliaSelect,
    MuiInputText,
    MuiSelect,
    MuiValidationWrapper,
    StoreNotificationComponent,
    TranslatableFieldComponent,
  },
  props: {
    serviceId: {
      type: Number,
      default: null,
    },
    onSuccess: {
      type: Function,
      default: () => {
      },
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isFormValid: false,
      isInitialized: false,
      originalInputs: null,
      inputs: {
        internalName: '',
        code: '',
        unit: null,
        group: null,
        glAccount: '',
        taxId: null,
        description: '',
        nameTranslations: {},
      },
    };
  },
  computed: {
    ...mapState(DOMAINS_MODEL.pricing.services, {
      service: state => state[SERVICES_SCOPES.service].data,
      serviceFormStatus: state => state[SERVICES_SCOPES.serviceForm].STATUS,
    }),
    isEditing() {
      return !!this.serviceId;
    },
    modalTitle() {
      const editTitle = this.isPreview ? 'Preview service' : 'Edit service';
      return this.isEditing ? editTitle : 'Create new service';
    },
    hasSameValues() {
      return isEqual(this.inputs, this.originalInputs);
    },
    isButtonDissabled() {
      return !this.isFormValid || (this.isEditing && this.hasSameValues);
    },
  },
  async created() {
    this.algoliaIndexes = algoliaIndexes;
    this.SERVICES_SCOPES = SERVICES_SCOPES;
    this.DOMAINS_MODEL = DOMAINS_MODEL;
    this.SERVICE_GROUPS = map(SERVICE_GROUPS, group => ({ label: upperFirst(group), value: group }));
    this.SERVICE_UNITS = map(SERVICE_UNITS, unit => ({ label: upperFirst(unit), value: unit }));

    if (this.isEditing) {
      await this.getService(this.serviceId);
      this.inputs.internalName = get(this.service, 'internalName');
      this.inputs.code = get(this.service, 'code');
      this.inputs.unit = get(this.service, 'unit');
      this.inputs.group = get(this.service, 'group');
      this.inputs.glAccount = get(this.service, 'glAccount');
      this.inputs.taxId = get(this.service, 'tax.id');
      this.inputs.description = get(this.service, 'description') || '';
      this.inputs.nameTranslations = cloneDeep(get(this.service, 'nameTranslations'));

      this.originalInputs = cloneDeep(this.inputs);
    }

    this.isInitialized = true;
  },
  beforeDestroy() {
    this.setData({
      scope: SERVICES_SCOPES.service,
      value: null,
    });
  },
  methods: {
    upperFirst,
    ...mapMutations(DOMAINS_MODEL.pricing.services, ['setData']),
    ...mapActions(DOMAINS_MODEL.pricing.services, ['getService', 'postService', 'putService']),
    async serviceRequest() {
      const request = this.isEditing ? this.putService : this.postService;
      const postData = {
        ...omit(this.inputs, ['nameTranslations']),
        ...mapKeys(this.inputs.nameTranslations, (_value, key) => `name${upperFirst(key)}`),
      };
      const data = this.isEditing ? { serviceId: this.serviceId, data: postData } : postData;

      await request(data);

      if (!this.serviceFormStatus.ERROR) {
        const action = this.isEditing ? 'edited' : 'created';
        const message = `Service successfully <span class="emobg-font-weight-semibold">${action}</span>!`;
        this.$emit('closeModal');
        this.$notify({
          message,
          textAction: '',
        });
        this.onSuccess();
      }
    },
  },
};
</script>

<template>
  <GenericModalComponent
    :title="modalTitle"
    :header="{ isClosable: true }"
    class="ServiceFormComponent"
    data-test-id="services-form"
    v-on="$listeners"
  >
    <template #alerts>
      <StoreNotificationComponent
        :store-domain="DOMAINS_MODEL.pricing.services"
        :scope="SERVICES_SCOPES.serviceForm"
        :is-editing="!!serviceId"
        element="service"
        data-test-id="notification"
      />
    </template>
    <template #body>
      <ui-loader
        v-if="!isInitialized"
        relative
        label="Loading service..."
      />
      <MuiValidationWrapper
        v-else
        class="row"
        @areAllValid="isValid => isFormValid = isValid"
      >
        <div class="col-6 emobg-border-right-1 emobg-border-color-ink-lighter">
          <template v-if="isPreview">
            <ContentCellComponent
              :value="service.internalName"
              label="Internal name"
              class="mb-4"
              data-test-id="internal_name"
            />
            <ContentCellComponent
              :value="service.code"
              label="Code"
              class="mb-4"
              data-test-id="code"
            />
            <ContentCellComponent
              :value="upperFirst(service.group)"
              label="Group"
              class="mb-4"
              data-test-id="group"
            />
            <ContentCellComponent
              :value="`${service.tax.name || service.tax.internalName} ${service.tax.tax}`"
              label="Tax"
              data-test-id="tax"
            />
          </template>
          <template v-else>
            <div class="mb-4">
              <MuiInputText
                v-model="inputs.internalName"
                v-validate="{
                  isRequired: true,
                }"
                label="Internal name*"
                name="internalName"
                placeholder="Enter internal name"
                data-test-id="internal_name-input"
              />
            </div>
            <div class="mb-4">
              <MuiInputText
                v-model="inputs.code"
                v-validate="{
                  isRequired: true,
                }"
                label="Code*"
                name="code"
                placeholder="Enter code"
                data-test-id="code-input"
              />
            </div>
            <div class="mb-4">
              <MuiSelect
                v-model="inputs.group"
                v-validate="{
                  isRequired: true,
                }"
                :options="SERVICE_GROUPS"
                label="Group*"
                name="group"
                class="w-100"
                placeholder="Select group"
                data-test-id="group-select"
              />
            </div>
            <div class="mb-4">
              <MuiAlgoliaSelect
                v-model="inputs.taxId"
                v-validate="{
                  isRequired: true,
                }"
                :index="algoliaIndexes.taxes"
                :title="tax => `${tax.internal_name} ${tax.tax}`"
                path-value="id"
                label="Tax*"
                name="tax"
                class="w-100"
                placeholder="Select tax"
                data-test-id="tax-select"
              />
            </div>
          </template>
        </div>
        <div class="col-6">
          <div class="mb-4">
            <TranslatableFieldComponent
              v-model="inputs.nameTranslations"
              :disabled="isPreview"
              label="Name"
              is-input-text
              placeholder="Enter name"
              data-test-id="name-input"
            />
          </div>
          <template v-if="isPreview">
            <ContentCellComponent
              :value="upperFirst(service.unit)"
              label="Unit"
              class="mb-4"
              data-test-id="unit"
            />
            <ContentCellComponent
              :value="service.glAccount"
              label="GL Account"
              class="mb-4"
              data-test-id="glAccount"
            />
            <ContentCellComponent
              :value="service.description"
              label="Description"
              data-test-id="description"
            />
          </template>
          <template v-else>
            <div class="mb-4">
              <MuiSelect
                v-model="inputs.unit"
                v-validate="{
                  isRequired: true,
                }"
                :options="SERVICE_UNITS"
                label="Unit*"
                name="unit"
                placeholder="Select unit"
                class="w-100"
                data-test-id="unit-select"
              />
            </div>
            <div class="mb-4">
              <MuiInputText
                v-model="inputs.glAccount"
                v-validate="{
                  isRequired: true,
                }"
                label="GL Account*"
                name="glAccount"
                class="w-100"
                placeholder="Enter GL Account"
                data-test-id="glAccount-input"
              />
            </div>
            <div class="mb-4">
              <ui-text-area
                :value="inputs.description"
                label="Description"
                name="description"
                class="w-100"
                placeholder="Enter a description"
                data-test-id="description-textarea"
                @changevalue="({ detail }) => inputs.description = detail"
              />
            </div>
          </template>
        </div>
      </MuiValidationWrapper>
    </template>
    <template #footer>
      <div class="d-flex justify-content-center justify-content-sm-end align-items-center">
        <CancelButton @click="$emit('closeModal')" />
        <ui-button
          v-if="!isPreview"
          :disabled="isButtonDissabled"
          :loading="serviceFormStatus.LOADING"
          class="wmin-initial"
          @clickbutton="serviceRequest"
        >
          {{ isEditing ? 'Save' : 'Create' }}
        </ui-button>
      </div>
    </template>
  </GenericModalComponent>
</template>
