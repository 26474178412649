var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "PageView",
    {
      staticClass: "ServicesListView",
      attrs: { "data-test-id": "services_list-view" },
    },
    [
      _c(
        "div",
        { staticClass: "d-flex align-items-center mb-2" },
        [
          _c("h1", { staticClass: "flex-grow-1" }, [_vm._v(" Services ")]),
          _c(
            "ui-button",
            {
              attrs: { "data-test-id": "create_service-button" },
              on: {
                clickbutton: function ($event) {
                  _vm.isFormOpened = true
                },
              },
            },
            [_vm._v(" Create new service ")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "position-relative" },
        [
          _vm.isLoading
            ? _c("ui-loader", {
                attrs: { absolute: "", "data-test-id": "loader" },
              })
            : _vm._e(),
          _c("MuiAlgoliaList", {
            ref: "services",
            attrs: {
              filters: _vm.getOperatorFilter(),
              "item-actions": _vm.itemsActions,
              "export-columns": _vm.exportColumns,
              facets: _vm.facets,
              labels: {
                searchTable: "Search by Id, model, city...",
              },
              "table-config": _vm.contentCells,
              index: _vm.ALGOLIA_INDEXES.services,
              "data-test-id": "list",
              "is-export-enabled": "",
            },
          }),
        ],
        1
      ),
      _vm.isFormOpened
        ? _c("ServiceFormComponent", {
            attrs: {
              "service-id": _vm.serviceIdToEdit,
              "is-preview": _vm.isPreviewMode,
              "on-success": _vm.refreshList,
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
      _vm.isDeleteFormOpened
        ? _c("DeleteCategory", {
            attrs: {
              service: _vm.serviceToDelete,
              "on-success": _vm.refreshList,
            },
            on: { closeModal: _vm.closeModal },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }