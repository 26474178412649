<script>
import { mapGetters } from 'vuex';
import { MuiAlgoliaList } from '@emobg/motion-ui';
import { camelCaseKeys, DELAY } from '@emobg/web-utils';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import { PageView } from '@/components';
import ALGOLIA_INDEXES from '@/constants/algoliaIndexes';
import { refreshAlgoliaStore } from '@/utils';
import {
  contentCells,
  facets,
} from './config/index';
import ServiceFormComponent from './components/ServiceFormComponent';
import DeleteCategory from './components/DeleteServiceModal';

export default {
  name: 'ServicesListView',
  components: {
    MuiAlgoliaList,
    PageView,
    ServiceFormComponent,
    DeleteCategory,
  },
  data() {
    return {
      isFormOpened: false,
      isLoading: false,
      serviceIdToEdit: null,
      isPreviewMode: false,
      serviceToDelete: null,
      isDeleteFormOpened: false,
    };
  },
  computed: {
    ...mapGetters(DOMAINS_MODEL.app.userAccount, [
      'getOperatorFilter',
    ]),
  },
  created() {
    this.ALGOLIA_INDEXES = ALGOLIA_INDEXES;
    this.contentCells = contentCells();
    this.exportColumns = this.contentCells;
    this.facets = facets;
    this.itemsActions = [
      {
        label: 'Preview service',
        method: ({ id }) => {
          this.isFormOpened = true;
          this.serviceIdToEdit = id;
          this.isPreviewMode = true;
        },
      },
      {
        label: 'Edit service',
        method: ({ id }) => {
          this.isFormOpened = true;
          this.serviceIdToEdit = id;
        },
      },
      {
        label: 'Delete service',
        type: 'danger',
        class: 'emobg-color-danger emobg-font-weight-semibold',
        method: service => {
          this.serviceToDelete = camelCaseKeys(service);
          this.isDeleteFormOpened = true;
        },
      },
    ];
  },
  methods: {
    refreshList() {
      this.isLoading = true;
      refreshAlgoliaStore(this.$refs.services, DELAY.extraLong, () => { this.isLoading = false; });
    },
    closeModal() {
      this.isFormOpened = false;
      this.serviceIdToEdit = null;
      this.isPreviewMode = false;
      this.isDeleteFormOpened = false;
      this.serviceToDelete = null;
    },
  },
};
</script>

<template>
  <PageView
    class="ServicesListView"
    data-test-id="services_list-view"
  >
    <div class="d-flex align-items-center mb-2">
      <h1 class="flex-grow-1">
        Services
      </h1>
      <ui-button
        data-test-id="create_service-button"
        @clickbutton="isFormOpened = true"
      >
        Create new service
      </ui-button>
    </div>
    <div class="position-relative">
      <ui-loader
        v-if="isLoading"
        absolute
        data-test-id="loader"
      />
      <MuiAlgoliaList
        ref="services"
        :filters="getOperatorFilter()"
        :item-actions="itemsActions"
        :export-columns="exportColumns"
        :facets="facets"
        :labels="{
          searchTable: 'Search by Id, model, city...',
        }"
        :table-config="contentCells"
        :index="ALGOLIA_INDEXES.services"
        data-test-id="list"
        is-export-enabled
      />
    </div>
    <ServiceFormComponent
      v-if="isFormOpened"
      :service-id="serviceIdToEdit"
      :is-preview="isPreviewMode"
      :on-success="refreshList"
      @closeModal="closeModal"
    />
    <DeleteCategory
      v-if="isDeleteFormOpened"
      :service="serviceToDelete"
      :on-success="refreshList"
      @closeModal="closeModal"
    />
  </PageView>
</template>
