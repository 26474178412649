var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "ServiceFormComponent",
        attrs: {
          title: _vm.modalTitle,
          header: { isClosable: true },
          "data-test-id": "services-form",
        },
        scopedSlots: _vm._u([
          {
            key: "alerts",
            fn: function () {
              return [
                _c("StoreNotificationComponent", {
                  attrs: {
                    "store-domain": _vm.DOMAINS_MODEL.pricing.services,
                    scope: _vm.SERVICES_SCOPES.serviceForm,
                    "is-editing": !!_vm.serviceId,
                    element: "service",
                    "data-test-id": "notification",
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                !_vm.isInitialized
                  ? _c("ui-loader", {
                      attrs: { relative: "", label: "Loading service..." },
                    })
                  : _c(
                      "MuiValidationWrapper",
                      {
                        staticClass: "row",
                        on: {
                          areAllValid: (isValid) => (_vm.isFormValid = isValid),
                        },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-6 emobg-border-right-1 emobg-border-color-ink-lighter",
                          },
                          [
                            _vm.isPreview
                              ? [
                                  _c("ContentCellComponent", {
                                    staticClass: "mb-4",
                                    attrs: {
                                      value: _vm.service.internalName,
                                      label: "Internal name",
                                      "data-test-id": "internal_name",
                                    },
                                  }),
                                  _c("ContentCellComponent", {
                                    staticClass: "mb-4",
                                    attrs: {
                                      value: _vm.service.code,
                                      label: "Code",
                                      "data-test-id": "code",
                                    },
                                  }),
                                  _c("ContentCellComponent", {
                                    staticClass: "mb-4",
                                    attrs: {
                                      value: _vm.upperFirst(_vm.service.group),
                                      label: "Group",
                                      "data-test-id": "group",
                                    },
                                  }),
                                  _c("ContentCellComponent", {
                                    attrs: {
                                      value: `${
                                        _vm.service.tax.name ||
                                        _vm.service.tax.internalName
                                      } ${_vm.service.tax.tax}`,
                                      label: "Tax",
                                      "data-test-id": "tax",
                                    },
                                  }),
                                ]
                              : [
                                  _c(
                                    "div",
                                    { staticClass: "mb-4" },
                                    [
                                      _c("MuiInputText", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: {
                                              isRequired: true,
                                            },
                                            expression:
                                              "{\n                isRequired: true,\n              }",
                                          },
                                        ],
                                        attrs: {
                                          label: "Internal name*",
                                          name: "internalName",
                                          placeholder: "Enter internal name",
                                          "data-test-id": "internal_name-input",
                                        },
                                        model: {
                                          value: _vm.inputs.internalName,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.inputs,
                                              "internalName",
                                              $$v
                                            )
                                          },
                                          expression: "inputs.internalName",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "mb-4" },
                                    [
                                      _c("MuiInputText", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: {
                                              isRequired: true,
                                            },
                                            expression:
                                              "{\n                isRequired: true,\n              }",
                                          },
                                        ],
                                        attrs: {
                                          label: "Code*",
                                          name: "code",
                                          placeholder: "Enter code",
                                          "data-test-id": "code-input",
                                        },
                                        model: {
                                          value: _vm.inputs.code,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.inputs, "code", $$v)
                                          },
                                          expression: "inputs.code",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "mb-4" },
                                    [
                                      _c("MuiSelect", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: {
                                              isRequired: true,
                                            },
                                            expression:
                                              "{\n                isRequired: true,\n              }",
                                          },
                                        ],
                                        staticClass: "w-100",
                                        attrs: {
                                          options: _vm.SERVICE_GROUPS,
                                          label: "Group*",
                                          name: "group",
                                          placeholder: "Select group",
                                          "data-test-id": "group-select",
                                        },
                                        model: {
                                          value: _vm.inputs.group,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.inputs, "group", $$v)
                                          },
                                          expression: "inputs.group",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "mb-4" },
                                    [
                                      _c("MuiAlgoliaSelect", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: {
                                              isRequired: true,
                                            },
                                            expression:
                                              "{\n                isRequired: true,\n              }",
                                          },
                                        ],
                                        staticClass: "w-100",
                                        attrs: {
                                          index: _vm.algoliaIndexes.taxes,
                                          title: (tax) =>
                                            `${tax.internal_name} ${tax.tax}`,
                                          "path-value": "id",
                                          label: "Tax*",
                                          name: "tax",
                                          placeholder: "Select tax",
                                          "data-test-id": "tax-select",
                                        },
                                        model: {
                                          value: _vm.inputs.taxId,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.inputs, "taxId", $$v)
                                          },
                                          expression: "inputs.taxId",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                          ],
                          2
                        ),
                        _c(
                          "div",
                          { staticClass: "col-6" },
                          [
                            _c(
                              "div",
                              { staticClass: "mb-4" },
                              [
                                _c("TranslatableFieldComponent", {
                                  attrs: {
                                    disabled: _vm.isPreview,
                                    label: "Name",
                                    "is-input-text": "",
                                    placeholder: "Enter name",
                                    "data-test-id": "name-input",
                                  },
                                  model: {
                                    value: _vm.inputs.nameTranslations,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.inputs,
                                        "nameTranslations",
                                        $$v
                                      )
                                    },
                                    expression: "inputs.nameTranslations",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm.isPreview
                              ? [
                                  _c("ContentCellComponent", {
                                    staticClass: "mb-4",
                                    attrs: {
                                      value: _vm.upperFirst(_vm.service.unit),
                                      label: "Unit",
                                      "data-test-id": "unit",
                                    },
                                  }),
                                  _c("ContentCellComponent", {
                                    staticClass: "mb-4",
                                    attrs: {
                                      value: _vm.service.glAccount,
                                      label: "GL Account",
                                      "data-test-id": "glAccount",
                                    },
                                  }),
                                  _c("ContentCellComponent", {
                                    attrs: {
                                      value: _vm.service.description,
                                      label: "Description",
                                      "data-test-id": "description",
                                    },
                                  }),
                                ]
                              : [
                                  _c(
                                    "div",
                                    { staticClass: "mb-4" },
                                    [
                                      _c("MuiSelect", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: {
                                              isRequired: true,
                                            },
                                            expression:
                                              "{\n                isRequired: true,\n              }",
                                          },
                                        ],
                                        staticClass: "w-100",
                                        attrs: {
                                          options: _vm.SERVICE_UNITS,
                                          label: "Unit*",
                                          name: "unit",
                                          placeholder: "Select unit",
                                          "data-test-id": "unit-select",
                                        },
                                        model: {
                                          value: _vm.inputs.unit,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.inputs, "unit", $$v)
                                          },
                                          expression: "inputs.unit",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "mb-4" },
                                    [
                                      _c("MuiInputText", {
                                        directives: [
                                          {
                                            name: "validate",
                                            rawName: "v-validate",
                                            value: {
                                              isRequired: true,
                                            },
                                            expression:
                                              "{\n                isRequired: true,\n              }",
                                          },
                                        ],
                                        staticClass: "w-100",
                                        attrs: {
                                          label: "GL Account*",
                                          name: "glAccount",
                                          placeholder: "Enter GL Account",
                                          "data-test-id": "glAccount-input",
                                        },
                                        model: {
                                          value: _vm.inputs.glAccount,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.inputs,
                                              "glAccount",
                                              $$v
                                            )
                                          },
                                          expression: "inputs.glAccount",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "mb-4" },
                                    [
                                      _c("ui-text-area", {
                                        staticClass: "w-100",
                                        attrs: {
                                          value: _vm.inputs.description,
                                          label: "Description",
                                          name: "description",
                                          placeholder: "Enter a description",
                                          "data-test-id":
                                            "description-textarea",
                                        },
                                        on: {
                                          changevalue: ({ detail }) =>
                                            (_vm.inputs.description = detail),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                          ],
                          2
                        ),
                      ]
                    ),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex justify-content-center justify-content-sm-end align-items-center",
                  },
                  [
                    _c("CancelButton", {
                      on: {
                        click: function ($event) {
                          return _vm.$emit("closeModal")
                        },
                      },
                    }),
                    !_vm.isPreview
                      ? _c(
                          "ui-button",
                          {
                            staticClass: "wmin-initial",
                            attrs: {
                              disabled: _vm.isButtonDissabled,
                              loading: _vm.serviceFormStatus.LOADING,
                            },
                            on: { clickbutton: _vm.serviceRequest },
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.isEditing ? "Save" : "Create") +
                                " "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }