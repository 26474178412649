var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "GenericModalComponent",
    _vm._g(
      {
        staticClass: "DeleteService",
        attrs: { header: null, size: _vm.SIZES.small },
        scopedSlots: _vm._u([
          {
            key: "body",
            fn: function () {
              return [
                _c("DeleteModalBodyComponent", {
                  attrs: {
                    title: "Delete this service?",
                    text: `#${_vm.service.id} ${_vm.service.internalName}`,
                  },
                }),
              ]
            },
            proxy: true,
          },
          {
            key: "footer",
            fn: function () {
              return [
                _c("CancelButton", {
                  on: {
                    click: function ($event) {
                      return _vm.$emit("closeModal")
                    },
                  },
                }),
                _c("DeleteButton", {
                  attrs: { loading: _vm.deleteServiceStatus.LOADING },
                  on: { click: _vm.removeService },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      },
      _vm.$listeners
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }