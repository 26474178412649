<script>
import { mapActions, mapState } from 'vuex';
import DOMAINS_MODEL from '@domains/DOMAINS_MODEL';
import {
  CancelButton,
  DeleteButton,
  DeleteModalBodyComponent,
  GenericModalComponent,
} from '@/components';

export default {
  components: {
    CancelButton,
    DeleteModalBodyComponent,
    GenericModalComponent,
    DeleteButton,
  },
  props: {
    service: {
      type: Object,
      required: true,
    },
    onSuccess: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    ...mapState(DOMAINS_MODEL.pricing.services, {
      deleteServiceStatus: state => state.deleteService.STATUS,
    }),
  },
  methods: {
    async removeService() {
      await this.deleteService(this.service.id);
      this.$emit('closeModal');
      if (!this.deleteServiceStatus.ERROR) {
        this.$notify({
          message: 'Service successfully <span class="emobg-font-weight-semibold">deleted</span>',
          textAction: '',
        });
        this.onSuccess();
      }
    },
    ...mapActions(DOMAINS_MODEL.pricing.services, [
      'deleteService',
    ]),
  },
};
</script>
<template>
  <GenericModalComponent
    :header="null"
    :size="SIZES.small"
    class="DeleteService"
    v-on="$listeners"
  >
    <template #body>
      <DeleteModalBodyComponent
        title="Delete this service?"
        :text="`#${service.id} ${service.internalName}`"
      />
    </template>
    <template #footer>
      <CancelButton @click="$emit('closeModal')" />
      <DeleteButton
        :loading="deleteServiceStatus.LOADING"
        @click="removeService"
      />
    </template>
  </GenericModalComponent>
</template>
