import { sentenceCase } from '@emobg/web-utils';
export { contentCells } from './contentCells';

export const facets = [
  {
    type: 'refinementList',
    props: {
      title: 'Group',
      attributeName: 'group',
      transformValue: sentenceCase,
    },
  },
  {
    type: 'refinementList',
    props: {
      title: 'Unit',
      attributeName: 'unit',
      transformValue: sentenceCase,
    },
  },
];
