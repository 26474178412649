export const SERVICE_GROUPS = [
  'subscription',
  'time',
  'mileage',
  'insurance',
  'excess',
  'promotion',
  'other',
];
export const SERVICE_UNITS = [
  'hour',
  'day',
  'km',
  'other',
];
