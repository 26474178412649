import { sentenceCase } from '@emobg/web-utils';

export function contentCells() {
  return [
    {
      attributeName: 'id',
      title: 'ID',
      displayPriority: 1,
      minWidth: 80,
    },
    {
      attributeName: 'name',
      title: 'Name',
      displayPriority: 1,
      minWidth: 300,
      transformResult: service => service.name || service.internal_name,
    },
    {
      attributeName: 'code',
      title: 'Code',
      displayPriority: 1,
      minWidth: 120,
    },
    {
      attributeName: 'group',
      title: 'Group',
      displayPriority: 1,
      minWidth: 100,
      transformValue: sentenceCase,
    },
    {
      attributeName: 'unit',
      title: 'Unit',
      displayPriority: 1,
      minWidth: 100,
      transformValue: sentenceCase,
    },
    {
      attributeName: 'name',
      title: 'External name',
      displayPriority: 0,
      minWidth: 100,
    },
    {
      attributeName: 'internal_name',
      title: 'Internal name',
      displayPriority: 0,
      minWidth: 100,
    },
    {
      attributeName: 'tax.name',
      title: 'Tax name',
      displayPriority: 0,
      minWidth: 100,
    },
    {
      attributeName: 'tax.tax',
      title: 'Tax',
      displayPriority: 0,
      minWidth: 100,
    },
  ];
}
